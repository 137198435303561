import { Api, tags } from "../..";
import { IContentCategory, ITag } from "shared/types";

export const tagsApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    addTag: builder.mutation<void, ITag & { image_id?: number }>({
      query: (data) => ({ url: "api/admin-tags/", method: "POST", body: data }),
      invalidatesTags: [tags.interests],
    }),
    deleteTag: builder.mutation<void, IContentCategory>({
      query: (data) => ({
        url: `api/admin-tags/${data.id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [tags.interests],
    }),
    changeTag: builder.mutation<void, IContentCategory>({
      query: (data) => ({
        url: `api/admin-tags/${data.id}/`,
        method: "PATCH",
        body: { name: data.name },
      }),
      invalidatesTags: [tags.interests],
    }),
  }),
});

export const { useAddTagMutation, useDeleteTagMutation, useChangeTagMutation } =
  tagsApi;
