import { ChangeEvent, memo, useState } from "react";
import { IContentCategory } from "shared/types";
import { Button } from "shared/ui/Buttons";
import { CheckIcon, DownloadIcon, PlusIcon } from "shared/ui/Icons";
import { Input, InputWithOptions } from "shared/ui/Inputs";
import { ScrollBar } from "shared/ui/ScrollBar";
import { Tooltip } from "shared/ui/Tooltip";
import { useShowConfirmModal } from "entities/Modals/ConfirmModal";
import styles from "./EditCategories.module.scss";
import { useLoadPhotoModal } from "entities/Modals/LoadPhotoModal";
import { LoaderHooks, useNotification } from "shared/hooks/shared";

interface EditCategoriesProps {
  categories: IContentCategory[];
  addCategory: ({ name }: { name: string; image_id?: number }) => void;
  deleteCategory: (category: IContentCategory) => void;
  editCategory: (category: IContentCategory) => void;
}

interface CategoryInputProps {
  category: IContentCategory;
  deleteCategory: (category: IContentCategory) => void;
  editCategory: (category: IContentCategory) => void;
}

const CategoryInput = memo(
  ({ category, deleteCategory, editCategory }: CategoryInputProps) => {
    const [newCategory, setNewCategory] = useState(category.name);
    const [disabled, setDisabled] = useState(true);

    const { open } = useShowConfirmModal();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== newCategory) {
        setNewCategory(event.target.value);
      }
    };

    const handleEditClick = () => {
      setDisabled((state) => !state);
    };

    const handleDeleteClick = () => {
      open({
        title: `Удалить категорию ${category.name}?`,
        buttonText: "удалить",
        onConfirmCallback: () => {
          deleteCategory(category);
        },
      });
    };

    const handleSubmit = () => {
      editCategory({ ...category, name: newCategory });
    };

    return (
      <div className={styles.category}>
        <InputWithOptions
          value={newCategory}
          disabled={disabled}
          autofocus={true}
          onChange={handleChange}
          editHandler={handleEditClick}
          deleteHandler={handleDeleteClick}
          className={styles.categoryInput}
        />
        <Tooltip tooltip={"Сохранить"}>
          <Button
            type="solid"
            icon={<CheckIcon height={24} width={24} color="white" />}
            className={styles.categoryButton}
            disabled={newCategory === category.name}
            onClick={handleSubmit}
          />
        </Tooltip>
      </div>
    );
  }
);

export const EditCategories = ({
  categories = [],
  addCategory,
  deleteCategory,
  editCategory,
}: EditCategoriesProps) => {
  const { show, hide } = useNotification();
  const { imageFile, openPhotoModal, resetPhoto } = useLoadPhotoModal();
  const { loadMedia } = LoaderHooks.useImageLoader();
  const [newTag, setNewTag] = useState("");

  const setTagText = (event: ChangeEvent<HTMLInputElement>) => {
    if (newTag !== event.target.value) {
      setNewTag(event.target.value);
    }
  };

  const handleAddTagClick = async () => {
    if (newTag) {
      let imageId: number | null | undefined = null;

      try {
        show({
          text: "Загрузка",
          withLoader: true,
          autoClose: false,
        });
        if (imageFile instanceof File) {
          imageId = await loadMedia(imageFile, "image");
        }
        await addCategory({
          name: newTag,
          image_id: imageId || undefined,
        });
        setNewTag("");
        resetPhoto();
        hide();
      } catch (error) {
        hide();
        const errorMsg =
          (error as any)?.data?.errors?.name ||
          "Ошибка при добавлении категории";

        show({
          text: errorMsg,
          withLoader: false,
          autoClose: true,
          isError: true,
        });
      }
    }
  };

  return (
    <div className={styles.categories}>
      <h2 className={styles.categories__subtitle}>Категории</h2>
      <div className={styles.categories__add}>
        <Input
          placeholder="Название категории..."
          value={newTag}
          onChange={setTagText}
          className={styles.categories__input}
        />
        <div className={styles.categories__buttons}>
          <Tooltip tooltip="Добавить">
            <Button
              type="solid"
              icon={<PlusIcon color="white" height={24} width={24} />}
              className={styles.categories__button}
              onClick={handleAddTagClick}
            />
          </Tooltip>
          <Tooltip tooltip="Загрузить изображение">
            <Button
              type="solid"
              icon={<DownloadIcon color="white" height={30} width={30} />}
              className={styles.categories__button}
              onClick={openPhotoModal}
            />
          </Tooltip>
        </div>
      </div>
      <ScrollBar height={"48vh"}>
        <div className={styles.categories__list}>
          {categories &&
            categories.map((category: IContentCategory) => (
              <CategoryInput
                category={category}
                editCategory={editCategory}
                deleteCategory={deleteCategory}
                key={category.id}
              />
            ))}
        </div>
      </ScrollBar>
    </div>
  );
};
